import { Routes } from '@angular/router';
import { CondensedComponent } from './core/components';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'tasks',
    pathMatch: 'full'
  },
  {
    path: '',
    component: CondensedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'clients',
        loadChildren: 'app/pages/clients/clients.module#ClientsModule',
        data: {
          breadcrumb: { label: 'Юр. лица' }
        }
      },
      {
        path: 'recognition',
        loadChildren: 'app/pages/tasks/task-recognition-result/task-recognition-result.module#TaskRecognitionResultModule',
        data: {
          breadcrumb: { label: 'Отчет по распознаванию' }
        }
      },
      {
        path: 'catalog',
        loadChildren: 'app/pages/product-catalog/product-catalog.module#ProductCatalogModule',
        data: {
          breadcrumb: { label: 'Каталог' }
        }
      },
      {
        path: 'order-time',
        loadChildren: 'app/pages/order-time/order-time.module#OrderTimeModule',
        data: {
          breadcrumb: { label: 'Время заказов' }
        }
      },
      // {
      //   path: 'catalog',
      //   loadChildren: 'app/pages/catalog/catalog.module#CatalogModule'
      // },
      {
        path: 'goals',
        loadChildren: 'app/pages/goals/goals.module#GoalsModule'
      },
      {
        path: 'statistics',
        loadChildren: 'app/pages/statistics/statistics.module#StatisticsModule'
      },
      {
        path: 'tasks',
        loadChildren: 'app/pages/tasks/tasks.module#TasksModule',
        data: {
          breadcrumb: { label: 'Юр. лица' }
        }
      },
      {
        path: 'auto-orders',
        loadChildren: 'app/pages/auto-orders/auto-orders.module#AutoOrdersModule'
      },
      {
        path: 'min-order-amount',
        loadChildren: 'app/pages/minimum-order-amounts/minimum-order-amounts.module#MinimumOrderAmountsModule'
      },
      {
        path: 'plans',
        loadChildren: 'app/pages/plans/plans.module#PlansModule'
      },
      {
        path: 'manufacturer',
        loadChildren: 'app/pages/manufacturer/manufacturer.module#ManufacturerModule'
      },
      {
        path: 'manufacturers',
        loadChildren: 'app/pages/manufacturers/manufacturers.module#ManufacturersModule'
      },
      {
        path: 'documents',
        loadChildren: 'app/pages/documents/documents.module#DocumentsModule'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '**', redirectTo: 'tasks' }
];
