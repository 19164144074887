import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { API_URL, USER_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Client, ClientFilterCriteria, ClientInfo, ClientsData } from '@Mesh/core/models/client';

@Injectable({
    providedIn: 'root'
})

export class ClientService {
    constructor(private readonly http: HttpClient) { }

    readonly connectionString = API_URL;

    getClientById(clientSapId: number): Observable<Client> {
        return this.http.get<Client>(`${this.connectionString}/client/${clientSapId}`);
    }

    getClientDetails(clientSapId: number): Observable<Client> {
        let params = new HttpParams();
        params = params.append('clientSapId', `${clientSapId}`);
        return this.http.get<Client>(`${USER_URL}/client/details`, { params });
    }

    getClientsByParams(param: ClientFilterCriteria): Observable<ClientsData> {
        let params = new HttpParams();
        if (param.cityName) {
            params = params.append('cityName', `${param.cityName}`);
        }
        if (param.regionName) {
            params = params.append('regionName', `${param.regionName}`);
        }
        if (param.clientNamePattern) {
            params = params.append('clientNamePattern', `${param.clientNamePattern}`);
        }
        if (param.page) {
            params = params.append('page', `${param.page}`);
        }
        if (param.size) {
            params = params.append('size', `${param.size}`);
        }
        if (param.clientSapIds) {
            params = params.append('clientSapIds', `${param.clientSapIds}`);
        }
        return this.http.get<ClientsData>(`${USER_URL}/client`, { params });
    }

    getClients(param: ClientFilterCriteria): Observable<ClientsData> {
        let params = new HttpParams();
        if (param.page) {
            params = params.append('page', `${param.page}`);
        } else {
            params = params.append('page', `0`);
        }
        if (param.size) {
            params = params.append('size', `${param.size}`);
        } else {
            params = params.append('size', `10`);
        }
        if (param.cityName) {
            params = params.append('cityName', `${param.cityName}`);
        }
        if (param.regionName) {
            params = params.append('regionName', `${param.regionName}`);
        }
        if (param.clientNamePattern) {
            params = params.append('clientNamePattern', `${param.clientNamePattern}`);
        }
        if (param.sort) {
            params = params.append('sort', `${param.sort}`);
        }
        if (param.direction) {
            params = params.append('direction', `${param.direction}`);
        }
        if (param.clientSapIds) {
            params = params.append('clientSapIds', `${param.clientSapIds}`);
        }
        return this.http.get<ClientsData>(`${USER_URL}/client`, { params });
    }

    getClientInfo(clientSapId: number): Observable<ClientInfo> {
        const queryString = '/client/info';
        let params = new HttpParams();
        params = params.append('clientSapId', `${clientSapId}`);
        return this.http.get<ClientInfo>(`${this.connectionString}${queryString}`, { params });
    }

    createClientInfo(clientInfo: ClientInfo): Observable<ClientInfo> {
        const queryString = '/client/info';
        let params = new HttpParams();
        params = params.append('clientSapId', `${clientInfo.clientSapId}`);
        return this.http.put<ClientInfo>(`${this.connectionString}${queryString}`, clientInfo, { params });
    }

    patchClientInfo(clientInfo: ClientInfo): Observable<ClientInfo> {
        const queryString = '/client/info';
        let params = new HttpParams();
        params = params.append('clientSapId', `${clientInfo.clientSapId}`);
        return this.http.patch<ClientInfo>(`${this.connectionString}${queryString}`, clientInfo, { params });
    }

    getBonusClient(clientSapId: number): Observable<{ clientSapId: number, account: number }> {
        return this.http.get<{ clientSapId: number, account: number }>(`${USER_URL}/bonus/${clientSapId}`);
    }

}
