import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/auth/auth.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pluck, switchMap } from 'rxjs/operators';
import { AuthService } from '@Mesh/core/services/api/auth/auth.service';
import { Router } from '@angular/router';
import { loadApplicationSettings } from '../../actions/startup/application-settings.actions';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { IUserInfo } from '@Mesh/core/models/user';

@Injectable()
export class AuthEffects {
  constructor(private actions: Actions, private authService: AuthService, private feathersService: FeathersService, private router: Router) {
  }

  initialization$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.init),
      switchMap(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo')) as IUserInfo;
        if (userInfo && userInfo.accessToken && userInfo.user) {
          this.setAuthInfo();
          return [fromActions.setTokenSuccess({ userInfo }), loadApplicationSettings()];
        }
        return [];
      })
    )
  );

  getToken$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.getToken),
      pluck('params'),
      switchMap(params => this.authService.getToken(params)),
      switchMap(userInfo => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        this.setAuthInfo();
        this.router.navigate(['/']);
        return [fromActions.getTokenSuccess({ userInfo })];
      })
    )
  );

  getSuccess$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.getTokenSuccess),
      pluck('userInfo'),
      switchMap(userInfo => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        this.setAuthInfo();
        return [loadApplicationSettings()];
      })
    )
  );

  refreshSuccess$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.refreshTokenSuccess),
      pluck('userInfo'),
      switchMap(userInfo => {
        this.setAuthInfo();
        return [loadApplicationSettings()];
      })
    )
  );

  private setAuthInfo(): void {
    this.feathersService.authenticate(this.authService.accessToken, this.authService.user);
    this.authService.publishStoredToken();
  }
}
