import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL } from '@Env/environment';
import { EnableOutletGoal, PlanProgress, PlanProgressConfig, PlanProgressMaterial, SalePlan, SalePlanEnable } from '@Mesh/core/models/APImodels/saleplan/saleplans';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import { SalesPlanDisableRequestModel } from '@Mesh/core/models/APImodels/saleplan/sales-plan-disable-request-model';

@Injectable({
  providedIn: 'root'
})
export class SalePlanService {

  readonly connectionString = `${SALEPLAN_URL}/v1/sale-plan`;

  constructor(private readonly http: HttpClient) {
  }

  getSalePlanProgress(props: { addressSapIds: number[], planIds: number[] }): Observable<PlanProgress[]> {
    let params = new HttpParams();
    props.addressSapIds.forEach(a => params = params.append('addressSapId', `${a}`));
    props.planIds.forEach(p => params = params.append('planIds', `${p}`));
    return this.http.get<PlanProgress[]>(`${this.connectionString}/progress`, { params }).pipe(map(e => e.map(plan => ({
      ...plan,
      currentPoints: 50 + Math.floor(Math.random() * 50),
      targetPoints: 50 + Math.floor(Math.random() * 50)
    }))));
  }

  getSalePlanProgressMaterial(props: { addressSapIds: number[], planId?: number }): Observable<PlanProgressMaterial[]> {
    let params = new HttpParams();
    props.addressSapIds.forEach(a => params = params.append('addressSapId', `${a}`));
    if (props.planId) {
      params = params.append('planId', `${props.planId}`);
    }
    return this.http.get<PlanProgressMaterial[]>(`${this.connectionString}/progress/material`, { params });
  }

  getSalePlanPlans(addressSapId: number): Observable<SalePlan[]> {
    let params = new HttpParams();
    params = params.append('addressSapId', `${addressSapId}`);
    params = params.append('showOffPlan', 'true');
    return this.http.get<SalePlan[]>(`${this.connectionString}/plans`, { params }).pipe(map(sp => sp.map(s => ({ ...s, planId: s.plan_id }))));
  }

  getEnableSalePlans(): Observable<SalePlanEnable[]> {
    let params = new HttpParams();
    params = params.append('size', '10000');
    return this.http.get<any>(`${this.connectionString}/enable`, { params }).pipe(map(spd => spd.content));
  }

  enableSalePlan(data: EnableOutletGoal): Observable<SalePlanEnable> {
    return this.http.post<SalePlanEnable>(`${this.connectionString}/enable`, data);
  }

  disableSalePlan(id: number): Observable<any> {
    return this.http.delete<any>(`${this.connectionString}/enable/${id}`);
  }

  getSalePlanProgressConfigs(props: { addressSapIds: number[], planId?: number }): Observable<PlanProgressConfig[]> {
    let params = new HttpParams();
    props.addressSapIds.forEach(a => params = params.append('addressSapId', `${a}`));
    if (props.planId) {
      params = params.append('planId', `${props.planId}`);
    }
    return this.http.get<PlanProgressConfig[]>(`${this.connectionString}/plan-progress`, { params });
  }

  getBonus({ addressSapId, planIds }: { addressSapId: number, planIds: number[] }): Observable<Bonus[]> {
    let params = new HttpParams();
    params = params.append('addressSapId', `${addressSapId}`);
    planIds.forEach(p => params = params.append('planIds', `${p}`));
    return this.http.get<Bonus[]>(`${SALEPLAN_URL}/v1/bonus`, { params });
  }

  enableList(body: SalesPlanDisableRequestModel[]): Observable<any> {
    return this.http.post<any>(`${this.connectionString}/enable/list`, body);
  }
}
