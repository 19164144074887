import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from '@Mesh/core/models/client';
import { Outlet } from '@Mesh/core/models/outlet';
import { ClientService } from '@Mesh/core/services/api/deprecated/client.service';

@Component({
  selector: 'iql-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHeaderComponent implements OnInit {
  @Input() name: string;
  @Input() icon: string;
  @Input() status: string;
  @Input() dialog: any;
  @Input() nameClickable: boolean;
  @Output() onBack = new EventEmitter();
  @Output() onNameClick = new EventEmitter();
  outlet: Outlet;
  clientSupport: Client;
  private _sticky: boolean;

  constructor(private cdr: ChangeDetectorRef,
    private clientService: ClientService) {
  }

  get sticky(): boolean {
    return this._sticky;
  }

  @Input()
  set sticky(value: boolean) {
    this._sticky = value;
    this.cdr.detectChanges();
  }

  nameClick(): void {
    this.onNameClick.emit();
  }

  ngOnInit(): void {
    this.getClientIfSupportDialog();
  }

  private getClientIfSupportDialog(): void {
    if (this.dialog?.type === 'support' && this.dialog?.outlet?.clientSapId) {
      this.clientService.getClientDetails(this.dialog.outlet.clientSapId)
        .subscribe(client => {
          this.clientSupport = client;
          this.cdr.markForCheck();
        }, error => {
          console.error('Error fetching client data:', error);
        });
    }
  }
}
