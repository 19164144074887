import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { ChatService } from '../chat.service';
import { DialogByClient, DialogByClientParams, DialogClient, UserDialog } from '../chat';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChatDialogService, ResponseType } from '../chat-dialog/chat-dialog.service';
import { PagesToggleService } from '../../../../core/services/chat/toggler.service';
import { removeEmptyParams } from '../../../helpers/remove-empty-params';
import { ChatTypeSwitchService } from '../chat-type-switch/chat-type-switch.service';
import { ChatType } from '../chat-type';

@Component({
  selector: 'iql-chat-client-main-page',
  templateUrl: './chat-client-main-page.component.html',
  styleUrls: ['./chat-client-main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatClientMainPageComponent implements OnInit {
  @Output() openedDialog = new EventEmitter<any>();
  @Output() changedType = new EventEmitter<string>();
  clientChatList: DialogByClient[];
  loading: boolean = false;
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  params: DialogByClientParams = {
    $limit: 20,
    $skip: 0,
  };
  chatType = ChatType.SALES_POINTS;

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private chatDialogService: ChatDialogService,
    private toggleService: PagesToggleService,
    private chatTypeSwitchService: ChatTypeSwitchService
  ) { }

  ngOnInit(): void {
    this.getClientChatList();
    this.chatDialogService.messagesSubject
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(({ type, data }) => {
        if (type === ResponseType.CREATED
          || type === ResponseType.PATCHED) {
          this.updateChats();
        }
      });
    this.toggleService.quickViewToggle.subscribe(message => {
      if (message === 'closed') {
        this.updateChats();
      }
    });
  }


  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  getClientChatList(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.chatService.getDialogsByClients(this.params)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(listDialogs => {
        this.clientChatList = this.clientChatList ? [...this.clientChatList, ...listDialogs.data] : listDialogs.data;
        this.setUnreadMessage();
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  onScroll(): void {
    this.params = {
      $limit: 20,
      $skip: this.clientChatList ? this.clientChatList.length : 0,
    };
    this.getClientChatList();
  }

  onOpenDialog(dialog: UserDialog): void {
    this.openedDialog.emit({ dialog })
  }

  onCollapsedGroup(element: HTMLElement): void {
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  updateChats(): void {
    this.clientChatList = [];
    this.getClientChatList();
  }

  onFilterChanged(data: { name?: string }): void {
    if (data && data.name) {
      this.params = {
        $limit: 20,
        $skip: 0,
        clientName: data.name,
      };
    } else {
      this.params = {
        $limit: 20,
        $skip: 0,
      };
    }
    this.updateChats();
  }

  onChangeTypeChat(type: string): void {
    this.changedType.emit(type);
  }

  private setUnreadMessage(): void {
    if (this.clientChatList && this.clientChatList.length) {
      const totalUnread = this.clientChatList.reduce((accumulator, dialog) => accumulator + dialog.totalUnread, 0);
      this.chatTypeSwitchService.setNotifications(ChatType.SALES_POINTS, totalUnread);
    }
  }

  trackClientChat(index: number, item: DialogByClient): number {
    return item.clientSapId;
  }
}
