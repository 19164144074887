import { Component, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, Input, AfterViewInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ChatService } from '../../../chatHR/service/chat.service';
import { PagesToggleService } from '../../../../../core/services/chat/toggler.service';
import { Subject, from, of } from 'rxjs';
import { DialogUserParams } from '../../../chatAcademy/models/chat';
import { Comment } from '../../../chatAcademy/models/comment';
import { User } from '../../../chatAcademy/models/user';
import { ChatTypeSwitchService } from '../../chat-type-switch/chat-type-switch.service';
import { FeathersService } from '../../../chatHR/service/feathers.service';
import { ChatDialogService } from '../../../chatHR/chat-dialog/chat-dialog.service';
import { VisitDialog, VisitDialogParams } from '../../../chatHR/models/visit-dialog';
import { ChatHrMainPageFilterData, ChatHrMainPageTab, TabsKey } from '../chat-hr';
import { ChatHrVisitApiService } from '../chat-hr-visit-data/chat-hr-visit.service';
import { ChatHrVisitItemComponent } from './chat-hr-visit-item/chat-hr-visit-item.component';
import { ToastrService } from 'ngx-toastr';
import { OpenedResume } from '../chat-hr-opened-resume/chat-hr-opened-resume';
import { ChatType } from '@Mesh/shared/modules/chat/chat-type';


export enum ChatSource {
  USERS_CHAT = "users-chat",
  VISIT_CHAT = "visit-chat",
  OPENED_RESUME = "opened-resume"
}

@Component({
  selector: 'iql-chat-hr-main-page',
  templateUrl: './chat-hr-main-page.component.html',
  styleUrls: ['./chat-hr-main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrMainPageComponent implements AfterViewInit, OnDestroy {
  @Input() currentUser: User;
  @Output() openedDialog = new EventEmitter<any>();
  @Output() changedType = new EventEmitter<string>();

  loading: boolean = false;
  userChatParams: DialogUserParams = {
    $limit: 20,
    $skip: 0,
    type: 'all',
    archived: 0,
  };
  visitChatParams: VisitDialogParams = {
    $limit: 20,
    $skip: 0,
    statusIds: [],
  };
  hrChatList: Comment[];
  visitChatList: VisitDialog[] = [];
  currentTab: ChatHrMainPageTab;
  isDisabledFilters: boolean = false;
  ChatSource = ChatSource;
  openedResume: OpenedResume[];
  private isOnScroll: boolean = false;
  private isAuthorizedHrChat: boolean = false;
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  scrolled: { currentScrollPosition: number };

  @ViewChildren(ChatHrVisitItemComponent) childVisitComponents: QueryList<ChatHrVisitItemComponent>;

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private chatDialogService: ChatDialogService,
    private toggleService: PagesToggleService,
    private chatTypeSwitchService: ChatTypeSwitchService,
    private feathersService: FeathersService,
    private chatHrVisitApiService: ChatHrVisitApiService,
    private toastr: ToastrService,
  ) { }

  ngAfterViewInit(): void {
    this.feathersService.isAuthChat()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(() => {
        this.getUsersChatList();
        this.getVisitChatList();
        this.isAuthorizedHrChat = true;
      });
    this.chatDialogService.onUpdateDialogList()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(() => {
        this.updateChats();
      });
    this.toggleService.quickViewToggle
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(message => {
        if (message === 'closed') {
          this.updateChats();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  getUsersChatList(): void {
    if (this.loading) {
      return;
    }
    if (this.currentTab.source === ChatSource.USERS_CHAT) {
      this.loading = true;
      this.chatService.getUsersDialogs(this.userChatParams)
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(listDialogs => {
          if (this.isOnScroll) {
            this.hrChatList = this.hrChatList ? [...this.hrChatList, ...listDialogs.data] : listDialogs.data;
          } else {
            this.hrChatList = listDialogs.data;
          }
          this.setUnreadMessage();
          this.loading = false;
          this.cdr.markForCheck();
        });
    } else {
      this.hrChatList = [];
    }
  }

  getVisitChatList(): void {
    if (this.loading) {
      return;
    }
    if (this.currentTab.source === ChatSource.VISIT_CHAT) {
      this.loading = true;
      this.chatService.getVisitDialogsList(this.visitChatParams)
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(listDialogs => {
          if (this.isOnScroll) {
            this.visitChatList = this.visitChatList ? [...this.visitChatList, ...listDialogs.data] : listDialogs.data;
          } else {
            this.visitChatList = listDialogs.data;
          }
          this.setUnreadMessage();
          this.loading = false;
          this.isOnScroll = false;
          this.cdr.markForCheck();
        });
    } else {
      this.visitChatList = [];
    }
  }

  onScroll(event: any): void {
    this.scrolled = event;
    this.isOnScroll = true;
    this.userChatParams = {
      ...this.userChatParams,
      $skip: this.hrChatList ? this.hrChatList.length : 0,
    };
    this.visitChatParams = {
      ...this.visitChatParams,
      $limit: 20,
      $skip: this.visitChatList ? this.visitChatList.length : 0,
    };
    this.getUsersChatList();
    this.getVisitChatList();
    this.cdr.markForCheck();
  }

  onOpenDialog(dialog: Comment | VisitDialog, type?: string): void {
    this.openedDialog.emit({ dialog, type, typeId: dialog.typeId });
  }

  updateChats(): void {
    this.visitChatParams = {
      ...this.visitChatParams,
      $skip: 0
    };
    this.userChatParams = {
      ...this.userChatParams,
      $skip: 0
    };
    this.hrChatList = [];
    this.visitChatList = [];
    this.getUsersChatList();
    this.getVisitChatList();
  }

  onFilterChanged(data: ChatHrMainPageFilterData): void {
    if (data && data.userId) {
      this.userChatParams = {
        type: 'all',
        archived: this.userChatParams.archived,
        $limit: 20,
        $skip: 0,
        userId: data.userId
      };
    } else {
      this.userChatParams = {
        archived: this.userChatParams.archived,
        type: 'all',
        $limit: 20,
        $skip: 0,
      };
    }

    const { statusId, ...restData } = data;
    this.visitChatParams = {
      ...this.visitChatParams,
      $limit: 20,
      $skip: 0,
      ...restData
    };
    if (statusId === undefined || statusId === null) {
      this.visitChatParams.statusId = null;
      this.visitChatParams.statusIds = this.currentTab.statusIds;
    } else if (this.currentTab.statusIds.includes(statusId)) {
      delete this.visitChatParams.statusIds;
      this.visitChatParams.statusId = statusId;
    } else {
      this.visitChatParams.statusIds = [];
      this.visitChatParams.statusId = statusId;
    }
    Object.keys(this.visitChatParams).forEach(key => {
      if (this.visitChatParams[key] === null) {
        delete this.visitChatParams[key];
      }
    });
    this.updateChats();
  }

  onChangeTypeChat(type: string): void {
    this.changedType.emit(type);
  }

  private setUnreadMessage(): void {
    if (this.hrChatList && this.hrChatList.length) {
      const totalUnread = this.hrChatList.reduce((accumulator, dialog) => accumulator + dialog.totalUnread, 0);
      this.chatTypeSwitchService.setNotifications(ChatType.RESPONSES_JOB, totalUnread);
    }

    if (this.visitChatList && this.visitChatList.length) {
      const totalUnread = this.visitChatList.reduce((accumulator, dialog) => accumulator + (dialog?.countUnread || 0), 0);
      this.chatTypeSwitchService.setNotifications(ChatType.RESPONSES_JOB, totalUnread);
    }
  }

  onChangeTab(tab: ChatHrMainPageTab): void {
    // this.isDisabledFilters = tab.source === ChatSource.USERS_CHAT;
    this.currentTab = tab;
    this.onFilterChatListByTab();
    if (this.isAuthorizedHrChat) {
      this.updateChats();
    }
  }

  onFilterChatListByTab(): void {
    if (this.currentTab.source === ChatSource.USERS_CHAT) {
      this.userChatParams = {
        ...this.userChatParams,
        archived: this.currentTab.key === TabsKey.ARCHIVE ? 1 : 0,
      }
    }
    if (this.currentTab.source === ChatSource.VISIT_CHAT) {
      const { statusId } = this.visitChatParams;
      this.visitChatParams = {
        ...this.visitChatParams,
        $limit: 20,
        $skip: 0,
      };
      if (statusId === undefined || statusId === null) {
        this.visitChatParams.statusIds = this.currentTab.statusIds;
      } else if (!this.currentTab.statusIds.includes(statusId)) {
        this.visitChatParams.statusIds = [];
      } else {
        delete this.visitChatParams.statusIds;
      }
    }
  }

  closeAllVisitsInfo() {
    this.childVisitComponents.forEach((visit) => {
      visit.onClosedInfo();
    });
  }

  onShowVisitInfo(visit: ChatHrVisitItemComponent) {
    this.closeAllVisitsInfo();
    visit.showInfo();
  }

  onToggleArchiveDialog(dialog: Comment) {
    const isArchived = dialog?.settings?.archived === 1;
    isArchived ? this.onUnarchiveDialog(dialog) : this.onArchiveDialog(dialog);
  }

  onUnarchiveDialog(dialog: Comment) {
    this.chatService.unArchiveDialog(dialog.type)
      .then((result) => {
        this.toastr.success('Диалог успешно извлечен из архива', '', {
          timeOut: 2000,
        });
        this.hrChatList = this.hrChatList.filter(item => item.type !== result.type);
        this.cdr.markForCheck();
      })
      .catch((error) => {
        this.toastr.error('Ошибка извлечения из архива', '', {
          timeOut: 2000,
        });
      });
  }

  onArchiveDialog(dialog: Comment) {
    this.chatService.archiveDialog(dialog.type, dialog.typeId)
      .then((result) => {
        this.toastr.success('Диалог успешно перемещен в архив', '', {
          timeOut: 2000,
        });
        this.hrChatList = this.hrChatList.filter(item => item.type !== result.type);
        this.cdr.markForCheck();
      })
      .catch((error) => {
        this.toastr.error('Ошибка перемещения в архив', '', {
          timeOut: 2000,
        });
      });
  }
}
