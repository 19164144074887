import { NotificationsModule } from './shared/modules/notifications/notifications.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { BlankComponent, CondensedComponent, RootLayout } from './core/components';
import { QuickviewComponent as PgQuickViewComponent } from './@pages/components/quickview/quickview.component';
import { QuickviewComponent } from './shared/quickview/quickview.component';
import { QuickviewService } from './shared/quickview/quickview.service';
import { SearchOverlayComponent } from './@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { pgCardSocialModule } from './@pages/components/card-social/card-social.module';
import {
    AccordionModule,
    AlertModule,
    BsDropdownModule,
    ButtonsModule,
    CollapseModule,
    ModalModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    TypeaheadModule
} from 'ngx-bootstrap';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { ProgressModule } from './@pages/components/progress/progress.module';
import { QuillModule } from 'ngx-quill';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { reducers } from './store/reducers';
import { effects } from './store/effects';
import { LoginComponent } from './pages/auth/login/login.component';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { PagesModule } from './@pages/components/pages.module';
import { SharedModule } from './shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChatModule } from '@Mesh/shared/modules/chat/chat.module';
import 'hammerjs';
import 'mousetrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatButtonToggleModule, RippleGlobalOptions } from '@angular/material';
import { AutosizeModule } from 'ngx-autosize';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { TreeModule } from 'angular-tree-component';
import { translateLoaderProvider, LanguageService } from './core/services/chat/language.service';
import { PagesToggleService } from './core/services/chat/toggler.service';
import { windowProviders } from './core/services/chat/window.service';
import { HttpModule } from '@angular/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ImageCropperModule } from 'ngx-image-cropper';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import { CartStoreModule } from './store/cart/card-store.module';
import { CatalogModalModule } from './pages/product-catalog/catalog-modal/catalog-modal.module';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localeRu, 'ru');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

export function countdownConfigFactory(): CountdownGlobalConfig {
    return { format: `mm:ss` } as any;
}

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 300,
        exitDuration: 0
    }
};

//https://github.com/angular/angular/issues/10541
export class AppHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        CondensedComponent,
        QuickviewComponent,
        SearchOverlayComponent,
        HeaderComponent,
        HorizontalMenuComponent,
        BlankComponent,
        RootLayout,
        LoginComponent,
        PgQuickViewComponent,
    ],
    imports: [
        TreeModule.forRoot(),
        AutosizeModule,
        CountdownModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        PagesModule,
        ProgressModule,
        pgListViewModule,
        pgCardModule,
        pgCardSocialModule,
        RouterModule.forRoot(AppRoutes),
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        pgTabsModule,
        PerfectScrollbarModule,
        pgSwitchModule,
        QuillModule.forRoot(),
        TranslateModule.forRoot({
            loader: translateLoaderProvider
        }),
        CartStoreModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        ChatModule,
        NgxDaterangepickerMd.forRoot(),
        FontAwesomeModule,
        MatButtonToggleModule,
        AngularSvgIconModule.forRoot(),
        NotificationsModule,
        ImageCropperModule,
        CatalogModalModule,
        ToastrModule.forRoot({
            timeOut: 15000,
            positionClass: 'toast-top-right',
        }),
    ],
    providers: [
        windowProviders,
        LanguageService,
        QuickviewService,
        PagesToggleService,
        { provide: LOCALE_ID, useValue: 'ru' },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: AppHammerConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
