import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL } from '@Env/environment';
import { IAutoOrderQueryParams, IClientsQueryParams, IQueryParams } from '@Mesh/core/models/query-params';
import { BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';
import { IResponse } from '@Mesh/core/models/IResponse';
import { IAutoOrder } from '@Mesh/pages/auto-orders/create-auto-order-types';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { IPageableList } from '../../../models/pageable';
import { DelayedOrderConfig, DelayedOrderConfigCriteria, DelayedOrderConfigPayload, OrderHistoryItem, OrderHistoryItemsCriteria } from '../../../models/order.model';
import { DelayedOrderUpdatePayload, DelayedOrder } from '../../../models/delayed-order';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) {
  }

  readonly baseUrl = `${SALEPLAN_URL}/v1/orders`;

  getOrdersRecommendedConfigs(params?: Partial<IAutoOrderQueryParams>): Observable<IResponse<IAutoOrder[]>> {
    return this.http.get<IResponse<IAutoOrder[]>>(`${this.baseUrl}/configuration/recommended`, { params: <HttpParams>params });
  }

  deleteOrdersRecommendedConfigs(orderId: number): Observable<IResponse<BonusConfiguration[]>> {
    return this.http.delete<IResponse<BonusConfiguration[]>>(`${this.baseUrl}/configuration/recommended/${orderId}`);
  }

  getOrderRecommendedConfigs(id: number): Observable<IAutoOrder> {
    return this.http.get<IAutoOrder>(`${SALEPLAN_URL}/v1/orders/configuration/recommended/${id}`);
  }

  getMotivationPrograms(): Observable<BonusConfiguration[]> {
    return this.http.get<BonusConfiguration[]>(`${SALEPLAN_URL}/v1/bonus/configuration`);
  }

  createOrdersRecommendedConfigs(data: IAutoOrder): Observable<IResponse<BonusConfiguration[]>> {
    return this.http.post<IResponse<BonusConfiguration[]>>(`${SALEPLAN_URL}/v1/orders/configuration/recommended`, data);
  }

  updateDelayedCart(addressSapId: number, payload: DelayedOrderUpdatePayload): Observable<DelayedOrder> {
    return this.http.put<DelayedOrder>(`${SALEPLAN_URL}/v1/cart/${addressSapId}/delayed`, payload);
  }

  requestInvoiceDelayedCart(delayedOrderId: number, criteria: { shouldRequestInvoice: boolean }): Observable<DelayedOrder> {
    const params = objectToParams(criteria);
    return this.http.put<DelayedOrder>(`${SALEPLAN_URL}/v1/cart/delayed/${delayedOrderId}/invoices`, {}, { params });
  }

  getOrdersByItem(criteria: Partial<OrderHistoryItemsCriteria>): Observable<IPageableList<OrderHistoryItem>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<OrderHistoryItem>>(`${SALEPLAN_URL}/v1/order-history/items`, { params });
  }

  getDelayedOrderConfigs(criteria: Partial<DelayedOrderConfigCriteria>): Observable<IPageableList<DelayedOrderConfig>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<DelayedOrderConfig>>(`${SALEPLAN_URL}/v1/orders/configuration/delayed`, { params });
  }

  getDelayedOrderConfigByIdentifier(type: string, identifier: string): Observable<DelayedOrderConfig> {
    return this.http.get<DelayedOrderConfig>(`${SALEPLAN_URL}/v1/orders/configuration/delayed/${type}/${identifier}`);
  }

  saveDelayedOrderConfig(payload: DelayedOrderConfigPayload): Observable<DelayedOrderConfig> {
    return this.http.post<DelayedOrderConfig>(`${SALEPLAN_URL}/v1/orders/configuration/delayed`, payload);
  }

  removeDelayedOrderConfig(payload: DelayedOrderConfigPayload): Observable<any> {
    return this.http.request('delete', `${SALEPLAN_URL}/v1/orders/configuration/delayed`, { body: payload });
  }
}
