import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, HostListener, ViewChild, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Store } from '@ngrx/store';
import { State } from '@Mesh/store/reducers';

@Component({
    selector: 'pg-menu-items',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [
        trigger('toggleHeight', [
            state(
                'close',
                style({
                    height: '0',
                    overflow: 'hidden'
                })
            ),
            state(
                'open',
                style({
                    height: '*'
                })
            ),
            transition('close => open', animate('140ms ease-in')),
            transition('open => close', animate('140ms ease-out'))
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewInit {
    brands: any[] = [];
    menuItems = [];
    @Input() sideBar: ElementRef;
    currentItem = null;
    isPerfectScrollbarDisabled = false;
    currentCategoryItem = null;

    @ViewChild('addMenu', { static: false }) addmenu: ElementRef;

    public config: PerfectScrollbarConfigInterface = {};

    constructor(private readonly _store: Store<State>) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.togglePerfectScrollbar();
        });
    }

    @HostListener('window:resize', [])
    onResize() {
        this.togglePerfectScrollbar();
    }

    togglePerfectScrollbar() {
        this.isPerfectScrollbarDisabled = window.innerWidth < 1025;
    }

    @Input()
    set Items(value) {
        this.menuItems = value;
    }

    toggleNavigationSub(event, item): void {
        console.log('toggleSub');
        event.preventDefault();
        if (this.currentItem && this.currentItem !== item) {
            this.currentItem['toggle'] = 'close';
        }
        this.currentItem = item;
        item.toggle = item.toggle === 'close' ? 'open' : 'close';
    }

    toggleNavigationSub1(event, item) {
        event.preventDefault();
        this.currentItem = item;
        item.toggle = item.toggle === 'close' ? 'open' : 'close';
    }

    onEnter(child: any, event: MouseEvent) {
        event.preventDefault();
        this.currentCategoryItem = child;
        this.brands = child.submenu ? child.submenu : [];

        const el: HTMLUnknownElement = this.sideBar.nativeElement;
        const menu: HTMLUnknownElement = this.addmenu.nativeElement;
        el.style.width = '425px';
        menu.style.width = '200px';
    }

    onLeave(event: MouseEvent, inMenu: boolean) {
        const target = event.relatedTarget as HTMLDivElement;
        event.preventDefault();
    if ((event.clientX < 238 || inMenu) && target && !target.classList.contains('ps__rail-y')) {
            const el: HTMLUnknownElement = this.sideBar.nativeElement;
            const menu: HTMLUnknownElement = this.addmenu.nativeElement;
            el.style.width = '238px';
            menu.style.width = '0px';
        }
    }
}
