import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import { select } from '@ngrx/store';
import { getApplicationSettingsLoadingStatus, getCategoriesValue } from '@Mesh/store/selectors/startup/application-settings.selectors';
import { loadStepStatuses } from '@Mesh/store/actions/task/tasks.actions';
import { selectSelectedOutletId } from '@Mesh/store/selectors/deprecated/outlets.selectors';
import { getCurrentTask } from '@Mesh/store/selectors/task/tasks.selectors';
import { ActivatedRoute, Params } from '@angular/router';
import { getTaskProgress } from '@Mesh/store/actions/task/task-progress.actions';
import { MetabaseStatistics } from '@Mesh/shared/app.constants';
import { Logout } from '../../../store/actions/auth/auth.actions';
import { getUser } from '../../../store/selectors/auth/auth.selector';

@Component({
  selector: 'iql-condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CondensedComponent extends RootLayout implements OnInit, OnDestroy, AfterViewInit {
  applicationSettingsState$ = this.store.pipe(select(getApplicationSettingsLoadingStatus));
  selectSelectedOutletId$ = this.store.pipe(select(selectSelectedOutletId));
  getCurrentTask$ = this.store.pipe(select(getCurrentTask));
  currentUser$ = this.store.select(getUser);
  menuLinks: any[];

  selectedOutletId;
  currentTask;
  hasMessages: boolean;

  private getParams(route: ActivatedRoute): Params {
    let params = route.snapshot.params;
    params = { ...route.snapshot.queryParams, ...params };
    if (route.children) {
      for (const r of route.children) {
        params = { ...this.getParams(r), ...params };
      }
    }
    return params;
  }

  getMenuLinks(): any {
    const links = [
      {
        label: 'Каталог',
        // toggle: 'close',
        // mToggle: 'close',
        iconType: 'pg',
        iconName: 'outlets',
        routerLink: '/catalog',
      },
      {
        label: 'Цели',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'goals',
        submenu: [
          {
            label: 'Конфигурации целей',
            routerLink: '/goals/configurations',
            iconType: 'pg',
            iconName: 'settings'
          },
          {
            label: 'Создание цели',
            routerLink: '/goals/create',
            iconType: 'pg',
            iconName: 'plus'
          },
          {
            label: 'Каталог',
            routerLink: '/goals/list',
            iconType: 'pg',
            iconName: 'menu'
          },
        ]
      },
      {
        label: 'Авто-заказы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'history',
        submenu: [
          {
            label: 'Конфигурации авто-заказов',
            routerLink: '/auto-orders/configurations',
            iconType: 'pg',
            iconName: 'settings'
          },
          {
            label: 'Создание авто-заказов',
            routerLink: '/auto-orders/create',
            iconType: 'pg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Мин. сумма заказа',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'icon-cart-menu',
        submenu: [
          {
            label: 'Правила мин. суммы заказа',
            routerLink: '/min-order-amount/configurations',
            iconType: 'pg',
            iconName: 'settings'
          },
          {
            label: 'Создание мин. суммы заказа',
            routerLink: '/min-order-amount/create',
            iconType: 'pg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Время заказов',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'icon-order-time',
        submenu: [
          {
            label: 'Правила времени заказов',
            routerLink: '/order-time/configurations',
            iconType: 'pg',
            iconName: 'settings'
          },
          {
            label: 'Создание времени заказов',
            routerLink: '/order-time/create',
            iconType: 'pg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Задания',
        routerLink: '/tasks',
        iconType: 'pg',
        iconName: 'tasks'
      },
      {
        label: 'Клиенты',
        routerLink: '/clients',
        iconType: 'pg',
        iconName: 'people'
      },
      {
        label: 'Активные Заказы',
        routerLink: '/clients/1/outlets/1/orders',
        iconType: 'pg',
        iconName: 'history'
      },
      {
        label: 'Статистика',
        iconType: 'pg',
        iconName: 'chart',
        routerLink: '/statistics/new-clients-by-day',
      }
    ];
    if (this.isPreviewMode) {
      return [
        {
          label: 'Контрагенты',
          routerLink: '/clients',
          iconType: 'pg',
          iconName: 'clients'
        },
        ...links,
        {
          label: 'Производители',
          routerLink: '/manufacturers',
          iconType: 'pg',
          iconName: 'manufacturers'
        },
        {
          label: 'Планы',
          routerLink: '/plans',
          iconType: 'pg',
          iconName: 'plans'
        },
        {
          label: 'Сотрудники ГКМ',
          routerLink: '/',
          iconType: 'pg',
          iconName: 'people'
        }
      ];
    }
    return links;
  }

  ngOnInit(): void {
    this.selectSelectedOutletId$.subscribe((selectedOutletId) => {
      this.selectedOutletId = selectedOutletId;
    });
    this.getCurrentTask$.subscribe((currentTask) => {
      this.currentTask = currentTask;
    });
    this.feathersService.service('tasks').on('shouldUpdate', ({ taskOutletClientId, clientSapId, id }) => {
      const params = this.getParams(this.route);
      if (+params.taskOutletClientId === taskOutletClientId && this.currentTask && this.currentTask.id === id) {
        this.store.dispatch(getTaskProgress({ clientIds: [clientSapId], taskId: id }));
      }
    });
    this.feathersService.service('task-steps').on('shouldUpdate', ({ taskOutletClientId, clientSapId, id }) => {
      const params = this.getParams(this.route);
      if (this.selectedOutletId === taskOutletClientId && +params.clientSapId === clientSapId) {
        this.store.dispatch(loadStepStatuses({ taskOutletClientId, stepId: [id] }));
      }
    });
    this.menuLinks = this.getMenuLinks();
    // this.store.select(getCategoriesValue).subscribe(categories => {
    //   const productsSubmenu = categories.map(category => ({
    //     label: category.categoryName,
    //     routerLink: `/catalog`,
    //     queryParams: { parId: category.categoryNameId },
    //     submenu: category.categories.map(subCategory => ({
    //       label: subCategory.categoryName,
    //       routerLink: `/catalog`,
    //       queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId },
    //       submenu: subCategory.manufacturers.map(manufacturer => ({
    //         label: manufacturer.manufacturerName,
    //         routerLink: `/catalog`,
    //         queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId, manId: manufacturer.manufacturerNameId },
    //         submenu: manufacturer.brands.map(brand => ({
    //           label: brand.brandName,
    //           routerLink: `/catalog`,
    //           queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId, manId: manufacturer.manufacturerNameId, brandId: brand.brandNameId },
    //           submenu: brand.brandFamilies.map(brandFamily => ({
    //             label: brandFamily.brandFamilyName,
    //             routerLink: `/catalog`,
    //             queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId, manId: manufacturer.manufacturerNameId, brandId: brand.brandNameId, brandFId: brandFamily.brandFamilyNameId },
    //           }))
    //         }))
    //       }))
    //     }))
    //   }));
    //   this.menuLinks = this.getMenuLinks(productsSubmenu);
    // });
  }

  ngAfterViewInit(): void {
    this.toggler.hasMessages.subscribe(hasMessages => {
      console.log('hasMessages:', hasMessages);
      this.hasMessages = hasMessages;

      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  logout(): void {
    this.store.dispatch(Logout());
  }
}

